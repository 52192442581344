<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({

  name: { label: 'Image Card', group: 'Glue', layouts: ['Atlantisbahamas'] },
  description: 'Copy on top with image that is clipped on the left or right that also has copy',
  fields: {
    isRight: { label: 'Clips to the right', type: 'checkbox' },
    copySubTitle: { type: 'text', label: 'Sub-Title' },
    copyTitle: { type: 'text', label: 'Title' },
    copyDescription: { type: 'textarea', label: 'Description' },
    image: { type: 'media', label: 'Image', breakpoints: {
      sm: { width: 304, height: 502 },
      md: { width: 800, height: 492 },
      lg: {
        width: 1006,
        height: 566,
      },
    } },
    link: { type: 'link', label: 'Image Button Link', enabled: false },
    videoMode: { type: 'select', label: 'Video Playback Settings', options: { clickToActivate: 'Click to Activate', playAutomatically: 'Play Automatically' } },
    wistiaId: { type: 'text', label: 'Wistia ID', enabled: false },
  },
  templates: [{ label: 'Image Card', fields: { copyDescription: { value: 'Italian Seafood Cuisine' }, copyTitle: { value: 'paranza by michael white' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Paranza\/paranza-outdoor-patio-straight.jpg' }, link: { value: { text: 'learn more', target: '_self', relationship: null, href: '\/new-page' } }, videoMode: { value: 'clickToActivate' } } }],
})

const mousingOver = ref(false)
const showVideo = ref(false)

if (
  props.fields.wistiaId
  && props.fields.wistiaId.enabled
  && props.fields.videoMode.value === 'playAutomatically'
)
  showVideo.value = true

function goToLink(force = false) {
  if (force || !props.fields.wistiaId || !props.fields.wistiaId.enabled)
    window.location.href = props.fields.link.value?.href
}
</script>

<template>
  <div class="group pointer-events-none">
    <div>
      <div
        class="pointer-events-auto relative text-white font-sans2 body overflow-hidden cursor-pointer  w-[304px] h-[502px] md:w-[800px] md:h-[492px] lg:w-[1006px] lg:h-[566px]"
        @mouseenter="mousingOver = true"
        @mouseleave="mousingOver = false"
        @click.prevent="goToLink()"
      >
        <VoixMedia :field="fields.image" loading="eager" class="hidden lg:block absolute lg:relative inset-0 object-cover object-center transform duration-300 w-full h-full" :class="{ 'scale-105': mousingOver, 'opacity-0': showVideo }" />

        <VoixMedia :field="fields.image" loading="eager" class="lg:hidden object-cover object-center w-full h-full" :class="{ 'opacity-0': showVideo }" />

        <div
          v-if="showVideo"
          class="absolute inset-0 z-10 w-full h-full overflow-hidden object-cover flex justify-center items-center bg-black pointer-events-auto"
        >
          <glue-media-wistia
            :video-id="fields.wistiaId.value"
            :play-on-load="true"
            class="w-full h-full aspect-video relative z-30"
          />
        </div>

        <div
          v-if="
            fields.wistiaId
              && fields.wistiaId.enabled
              && fields.videoMode.value === 'clickToActivate'
              && !showVideo
          "
          class="absolute z-20 inset-0 m-8 text-zinc-100 flex justify-center items-center pointer-events-auto"
          @click="showVideo = true"
        >
          <button>
            <svg
              width="100"
              height="100"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="31"
                cy="31"
                r="30"
                fill="black"
                fill-opacity="0.4"
                stroke="white"
                stroke-width="1.5"
              />

              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                transform="translate(24 21)"
                d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        <div
          class="hidden lg:block h-[200px] group-hover:h-[700px] bg-gradient-to-t from-black/50 group-hover:from-black/80 to-black/0 absolute bottom-0 left-0 right-0 transition-all duration-300 group-hover:opacity-100"
        />
        <div
          class="lg:hidden bg-gradient-to-t from-black/50 to-black/0 absolute bottom-0 left-0 right-0 transition-all duration-300 h-full"
        />
        <div class="absolute z-10 inset-0 flex flex-col justify-between">
          <div
            class="relative md:absolute z-10 top-0 left-0 body-14 uppercase font-bold font-sans"
            style="margin-left: 31px; margin-top: 45px; padding-right: 31px"
          >
            <span class="hidden md:block">{{ fields.copySubTitle.value }}</span>
          </div>
          <div>
            <div
              class="relative md:absolute z-10 md:bottom-0 md:left-0 md:w-2/5"
              style="padding: 43px 31px"
            >
              <div class="body-24 mb-3 uppercase font-light md:font-normal tracking-1-2">
                {{ fields.copyTitle.value }}
              </div>
              <div>
                {{ fields.copyDescription.value }}
              </div>
              <div class="lg:hidden uppercase font-bold">
                {{ fields.copySubTitle.value }}
              </div>

              <div
                v-if="fields.link && fields.link.enabled"
                class="mt-6"
              >
                <button
                  class="uppercase border duration-500 hover:bg-glueblue-600 border-white body-11 tracking-widest pb-2 pt-2 px-10 whitespace-nowrap overflow-hidden text-ellipsis text-white font-bold"
                  @click.prevent="goToLink(true)"
                >
                  {{ fields.link.value.text }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
